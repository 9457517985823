import { useContext, useState } from 'react';
import { AppBar, Box, Button, Dialog, DialogTitle, Switch, TextField, Toolbar, Typography } from '@mui/material';
import { BugReportTwoTone, SettingsTwoTone } from '@mui/icons-material';
import context from '../context';
import style from '../style';
import { br, btn, debug, go, onCall, type } from '../resources';
import Login from './login';
import Ring from './ring';
import api from '../api';

debug.log('DEBUG ENABLED');

const SettingsDialog = () => {
    const [eggs, setEggs] = useState(window.localStorage.getItem('eggs') == 'true');
    const [settingsDialogOpen, setSettingsDialogOpen] = useState(false);

    const handle = {
        eggs: {
            enabled: () => {
                window.localStorage.setItem('eggs', 'true');
                setEggs(true);
            },

            disabled: () => {
                window.localStorage.removeItem('eggs');
                setEggs(false);
            }
        },

        settingsDialog: {
            open: () => setSettingsDialogOpen(true),
            close: () => setSettingsDialogOpen(false)
        }
    };

    return <>
        <Dialog PaperProps={{ style: style.color.default }} fullWidth open={settingsDialogOpen} onClose={handle.settingsDialog.close}>
            <DialogTitle style={style.center()}>Settings</DialogTitle>
            <div style={style.flex('space-around', 'center')}>
                <Typography>Easter Eggs</Typography>
                <Switch checked={eggs} onClick={eggs ? handle.eggs.disabled : handle.eggs.enabled} style={style.clr(eggs ? 'orangered' : 'gray')} color="default"/>
            </div>
            {br(20)}
        </Dialog>
        <SettingsTwoTone style={{ ...style.margin.right(20), ...style.ptr }} onClick={handle.settingsDialog.open}/>
    </>;
};

const BugReportDialog = () => {
    const [bugReportDialogOpen, setBugReportDialogOpen] = useState(false);
    const [text, setText] = useState('');
    const [confirmDisabled, setConfirmDisabled] = useState(true);

    const minHelpTextLenght = 50;
    const helpText = `If you notice that something is obviously not working correctly or is not working at all, please let us know. Minimum of ${minHelpTextLenght} characters.`;

    const handle = {
        bugReportDialog: {
            open: () => setBugReportDialogOpen(true),
            close: () => setBugReportDialogOpen(false),

            change: e => {
                type(e, 'Bug Report', setText, 3000);
                setConfirmDisabled(e.target.value.length < 50);
            },

            cancel: () => {
                setText('');
                setConfirmDisabled(true);
                handle.bugReportDialog.close();
            },

            confirm: async () => {
                await api.report(text);
                handle.bugReportDialog.cancel();
            }
        }
    };

    return <>
        <Dialog PaperProps={{ style: style.color.default }} fullWidth open={bugReportDialogOpen} onClose={handle.bugReportDialog.close}>
            <DialogTitle style={style.center()}>Bug Report</DialogTitle>
            <TextField sx={style.sx} InputProps={style.inputProps} style={style.mg} multiline rows={10} placeholder={helpText} value={text} onChange={handle.bugReportDialog.change}/>
            <div style={style.flex('space-between', 'center')}>
                {btn(handle.bugReportDialog.cancel, 'cancel', style.color.red)}
                {btn(handle.bugReportDialog.confirm, 'confirm', { ...(confirmDisabled ? style.color.gray : style.color.green.light), disabled: confirmDisabled })}
            </div>
            {br(20)}
        </Dialog>
        <BugReportTwoTone style={{ ...style.margin.right(20), ...style.ptr }} onClick={handle.bugReportDialog.open}/>
    </>;
};

export default () => {
    const { set, setLoginDialogOpen, creds } = useContext(context);

    const btn = (click, text) => <Button color="inherit" variant="outlined" style={
        window.location.pathname.substring(1) == click ? style.color.green.light : style.mg
    } onClick={typeof click == 'string' && go(click) || click}>
        {text || typeof click == 'string' && click}
    </Button>;

    return <>
        <audio id="4AM">
            <source src={`${process.env.PUBLIC_URL}/audio/4AM.mp3`} type="audio/mpeg"/>
        </audio>
        <Login/>
        <Ring/>
        <Box><AppBar sx={{ border: 1, borderRadius: 2 }} position="static" style={{ ...style.color.green.dark, margin: 0, marginBottom: 30, display: onCall && 'none', zIndex: 10 }}><Toolbar>
            <SettingsDialog/>
            <BugReportDialog/>
            <Typography variant="h6" sx={{ flexGrow: 1 }}><div style={style.ptr} onClick={go()}>H O M E</div></Typography>
            {creds.userName ? [
                btn('calendar'),
                btn('users'),
                btn('profile'),
                btn(set.me.logout, 'sign out')
            ] : [
                btn('profile', 'sign up'),
                btn(() => setLoginDialogOpen(true), 'sign in')
            ]}
        </Toolbar></AppBar></Box>
    </>;
};
